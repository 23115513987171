// Importações comuns
import React, { useRef, useEffect, useState } from "react";
import { Button, IconButton, Slider } from "@material-ui/core";
import { PlayArrow, Pause, Speed, GetApp, VolumeUp } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Constante para o nome do localStorage
const LS_NAME = 'audioMessageRate';

// Estilos para o novo player (desktop)
const useNewStyles = makeStyles((theme) => ({
  audioPlayerContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.type === 'light' ? "#f0f0f0" : "#2c2f33", 
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
    width: "100%",
    justifyContent: "space-between",
    position: "relative",
  },
  progressBar: {
    width: "100%",
    height: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.type === 'light' ? "#e0e0e0" : "#ffffff50", 
    margin: "0 10px",
    position: "relative",
    cursor: "pointer",
  },
  progress: {
    height: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main, 
    width: "0%", 
    position: "absolute",
    top: "0",
    left: "0",
  },
  timeInfo: {
    color: theme.palette.text.primary, 
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    marginBottom: "-5px", 
  },
  playButton: {
    color: theme.palette.primary.main, 
  },
  rateButton: {
    color: theme.palette.primary.main, 
    display: "flex",
    alignItems: "center",
  },
  downloadContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  downloadButton: { // Você pode remover este estilo se não for mais necessário
    marginLeft: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    padding: "5px 10px",
    borderRadius: "5px",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "12px",
    transition: "0.3s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  icon: {
    fontSize: "1.2rem", // Reduziu de 1.5rem para 1.2rem
  },
  downloadIconSmall: {
    fontSize: "1.2rem",
    color: theme.palette.primary.main, 
    cursor: "pointer",
    transition: "0.3s ease-in-out",
  },
  volumeControl: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: "10px",
    position: "relative",
  },
  volumeIcon: {
    color: theme.palette.primary.main, 
    marginRight: "5px",
  },
  volumeSliderContainer: {
    position: "absolute",
    bottom: "40px",  // Ajuste a posição vertical conforme necessário
    left: "50%",      // Centraliza horizontalmente
    transform: "translateX(-50%)", // Compensa a largura para centralizar
    display: "none",
    width: "100px",
    zIndex: 10,
    backgroundColor: theme.palette.type === 'light' ? "#ffffff" : "#424242",
    borderRadius: "5px",
    padding: "5px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
  },
  volumeSlider: {
    width: "100%",
    color: theme.palette.text.primary, 
  },
  showVolumeSlider: {
    display: "block", 
  }
}));

// Estilos para o player móvel (OldAudioModal)
const useOldStyles = makeStyles((theme) => ({
  audioPlayerContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.type === 'light' ? "#f9f9f9" : "#2c2f33",
    padding: "8px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
    width: "100%",
    position: "relative",
  },
  playButton: {
    color: theme.palette.primary.main,
  },
  rateButton: {
    color: theme.palette.primary.main,
    marginLeft: "10px",
  },
  downloadIconSmall: {
    fontSize: "1rem",
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "auto",
    transition: "0.3s ease-in-out",
  },
}));

// Variável para controlar a reprodução ativa
let activeAudio = null;

// Componente para dispositivos móveis (versão antiga) com botão de download direto
const OldAudioModal = ({ url }) => {
  const classes = useOldStyles();
  const [audioRate, setAudioRate] = useState(parseFloat(localStorage.getItem(LS_NAME) || "1"));
  const [showButtonRate, setShowButtonRate] = useState(false);
  const audioRef = useRef(null);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = audioRate;
      localStorage.setItem(LS_NAME, audioRate);
    }
  }, [audioRate]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handlePlaying = () => {
      setShowButtonRate(true);
      // Pausar o áudio ativo anterior, se existir e não for este áudio
      if (activeAudio && activeAudio !== audioRef.current) {
        activeAudio.pause();
      }
      // Definir este áudio como ativo
      activeAudio = audioRef.current;
    };

    const handlePause = () => {
      setShowButtonRate(false);
      // Se este áudio era o ativo, limpar a referência
      if (activeAudio === audioRef.current) {
        activeAudio = null;
      }
    };

    const handleEnded = () => {
      setShowButtonRate(false);
      // Se este áudio era o ativo, limpar a referência
      if (activeAudio === audioRef.current) {
        activeAudio = null;
      }
    };

    audio.addEventListener("playing", handlePlaying);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("playing", handlePlaying);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("ended", handleEnded);
      // Limpa activeAudio se este áudio estava ativo
      if (activeAudio === audioRef.current) {
        activeAudio = null;
      }
    };
  }, []);

  const toggleRate = () => {
    let newRate;
    switch (audioRate) {
      case 0.5:
        newRate = 1;
        break;
      case 1:
        newRate = 1.5;
        break;
      case 1.5:
        newRate = 2;
        break;
      case 2:
        newRate = 0.5;
        break;
      default:
        newRate = 1;
    }
    setAudioRate(newRate);
  };

  const getAudioSource = () => {
    let sourceUrl = url;
    if (isIOS) {
      sourceUrl = sourceUrl.replace(".ogg", ".mp3");
    }
    return <source src={sourceUrl} type={isIOS ? "audio/mp3" : "audio/ogg"} />;
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split('/').pop(); 
      const objectURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objectURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  return (
    <div className={classes.audioPlayerContainer}>
      <audio ref={audioRef} controls>
        {getAudioSource()}
      </audio>
      {showButtonRate && (
        <Button
          style={{ marginLeft: "5px", marginTop: "-30px" }}
          onClick={toggleRate}
        >
          {audioRate}x
        </Button>
      )}
      {/* Botão de download direto */}
      <IconButton
        className={classes.downloadIconSmall}
        onClick={handleDownload}
        aria-label="Baixar áudio"
      >
        <GetApp fontSize="small" />
      </IconButton>
    </div>
  );
};

// Componente para desktops (versão nova)
const NewAudioModal = ({ url }) => {
  const theme = useTheme();
  const classes = useNewStyles();
  const audioRef = useRef(null);
  const [audioRate, setAudioRate] = useState(parseFloat(localStorage.getItem(LS_NAME) || "1"));
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const [hasPlayed, setHasPlayed] = useState(false);
  const [showRate, setShowRate] = useState(false); 
  const [volume, setVolume] = useState(100); 
  const [showVolume, setShowVolume] = useState(false); 
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Controle de playbackRate e armazenamento no localStorage
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = audioRate;
      localStorage.setItem(LS_NAME, audioRate);
    }
  }, [audioRate]);

  // Atualização do progresso e duração
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateProgress = () => {
      if (audio.duration) {
        const percent = (audio.currentTime / audio.duration) * 100;
        setProgress(percent);
        setCurrentTime(formatTime(audio.currentTime));
      }
    };

    const updateDuration = () => {
      if (audio.duration) {
        setDuration(formatTime(audio.duration));
      }
    };

    const onPlay = () => {
      setIsPlaying(true);
      // Gerencia a reprodução ativa
      if (activeAudio && activeAudio !== audioRef.current) {
        activeAudio.pause();
      }
      activeAudio = audioRef.current;
    };

    const onPause = () => {
      setIsPlaying(false);
      if (activeAudio === audioRef.current) {
        activeAudio = null;
      }
    };

    const onEnded = () => {
      setIsPlaying(false);
      setHasPlayed(false);
      setProgress(0);
      setCurrentTime("00:00");
      if (activeAudio === audioRef.current) {
        activeAudio = null;
      }
    };

    // Adiciona os event listeners
    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("loadedmetadata", updateDuration);
    audio.addEventListener("play", onPlay);
    audio.addEventListener("pause", onPause);
    audio.addEventListener("ended", onEnded);

    // Remove os event listeners no cleanup
    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("loadedmetadata", updateDuration);
      audio.removeEventListener("play", onPlay);
      audio.removeEventListener("pause", onPause);
      audio.removeEventListener("ended", onEnded);
      // Limpa activeAudio se este áudio estava ativo
      if (activeAudio === audioRef.current) {
        activeAudio = null;
      }
    };
  }, []);

  // Controle de reprodução
  const togglePlay = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      // Gerencia a reprodução ativa antes de reproduzir
      if (activeAudio && activeAudio !== audioRef.current) {
        activeAudio.pause();
      }
      audioRef.current.play().catch(error => {
        console.error("Falha ao reproduzir o áudio:", error);
      });
      activeAudio = audioRef.current;
    }
    setIsPlaying(!isPlaying);
  };

  // Alternar a velocidade de reprodução
  const toggleRate = () => {
    let newRate;
    switch (audioRate) {
      case 0.5:
        newRate = 1;
        break;
      case 1:
        newRate = 1.5;
        break;
      case 1.5:
        newRate = 2;
        break;
      case 2:
        newRate = 0.5;
        break;
      default:
        newRate = 1;
    }
    setAudioRate(newRate);
    setShowRate(true); 

    setTimeout(() => {
      setShowRate(false);
    }, 2000);
  };

  // Obter a fonte de áudio adequada
  const getAudioSource = () => {
    let sourceUrl = url;
    if (isIOS) {
      sourceUrl = sourceUrl.replace(".ogg", ".mp3");
    }
    return <source src={sourceUrl} type={isIOS ? "audio/mp3" : "audio/ogg"} />;
  };

  // Formatar o tempo em mm:ss
  const formatTime = (time) => {
    if (isNaN(time)) return "00:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Controle do progresso ao clicar na barra
  const handleProgressClick = (e) => {
    const width = e.currentTarget.clientWidth;
    const clickX = e.nativeEvent.offsetX;
    const duration = audioRef.current.duration;
    if (!isNaN(duration)) {
      audioRef.current.currentTime = (clickX / width) * duration;
    }
  };

  // Controle de volume
  const handleVolumeChange = (e, newValue) => {
    setVolume(newValue);
    if (audioRef.current) {
      audioRef.current.volume = newValue / 100;
    }
  };

  // Função para baixar o áudio
  const handleDownload = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split('/').pop(); 
      const objectURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objectURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  // Alternar a exibição do controle de volume
  const toggleVolume = () => {
    setShowVolume(true);
    // Timeout para esconder o controle de volume após 2 segundos
    setTimeout(() => setShowVolume(false), 2000);
  };

  return (
    <div className={classes.audioPlayerContainer}>
      <audio ref={audioRef} preload="auto">
        {getAudioSource()}
      </audio>
      <IconButton
        className={classes.playButton}
        onClick={togglePlay}
        aria-label={isPlaying ? "Pausar áudio" : "Reproduzir áudio"}
      >
        {isPlaying ? (
          <Pause className={classes.icon} />
        ) : (
          <PlayArrow className={classes.icon} />
        )}
      </IconButton>
      <div className={classes.progressBar} onClick={handleProgressClick}>
        <div className={classes.progress} style={{ width: `${progress}%` }} />
      </div>
      <div className={classes.timeInfo}>
        <IconButton
          className={classes.rateButton}
          onClick={toggleRate}
          aria-label="Alterar velocidade de reprodução"
        >
          <Speed className={classes.icon} />
        </IconButton>
        {showRate && <span style={{ marginLeft: "5px", color: theme.palette.primary.main }}>{audioRate}x</span>}
        <span style={{ color: theme.palette.text.primary }}>
          {currentTime} / {duration}
        </span>
      </div>

      <div className={classes.volumeControl}>
        <IconButton onClick={toggleVolume} aria-label="Controle de volume">
          <VolumeUp className={classes.volumeIcon} />
        </IconButton>
        <div className={`${classes.volumeSliderContainer} ${showVolume ? classes.showVolumeSlider : ""}`}>
          <Slider
            value={volume}
            onChange={handleVolumeChange}
            aria-labelledby="controle-de-volume"
            className={classes.volumeSlider}
          />
        </div>
      </div>

      <div className={classes.downloadContainer}>
        {/* Atualizado para chamar handleDownload diretamente */}
        <IconButton
          className={classes.downloadIconSmall}
          onClick={handleDownload}
          aria-label="Baixar áudio"
        >
          <GetApp className={classes.downloadIconSmall} />
        </IconButton>
        {/* Removido o segundo botão de download */}
      </div>
    </div>
  );
};

// Componente principal que decide qual UI renderizar
const AudioModal = ({ url }) => {
  // Função para detectar se o dispositivo é móvel
  const isMobileDevice = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipad|iPod/i.test(ua);
  };

  const mobile = isMobileDevice();

  return (
    <>
      {mobile ? (
        <OldAudioModal url={url} />
      ) : (
        <NewAudioModal url={url} />
      )}
    </>
  );
};

export default AudioModal;
